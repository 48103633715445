import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type ChatRoom = {
  __typename?: 'ChatRoom';
  adminId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  language?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  managerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  roomImageUrl?: Maybe<Scalars['String']>;
  roomName?: Maybe<Scalars['String']>;
  roomType?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  users?: Maybe<Array<Maybe<ChatRoomUser>>>;
};

export type ChatRoom2 = {
  __typename?: 'ChatRoom2';
  adminId?: Maybe<Scalars['ID']>;
  filename: Scalars['String'];
  height?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  managerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  roomImageUrl?: Maybe<Scalars['String']>;
  roomName?: Maybe<Scalars['String']>;
  roomType?: Maybe<Scalars['String']>;
  userIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  users?: Maybe<Array<Maybe<ChatRoomUser>>>;
  width?: Maybe<Scalars['String']>;
};

export type ChatRoomUser = {
  __typename?: 'ChatRoomUser';
  id?: Maybe<Scalars['ID']>;
  nickname?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
};

export type Content = {
  __typename?: 'Content';
  base?: Maybe<Scalars['String']>;
  baseSymbol?: Maybe<Scalars['String']>;
  change?: Maybe<Scalars['String']>;
  counter?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  dividendPerShare?: Maybe<Scalars['String']>;
  dividendRatio?: Maybe<Scalars['String']>;
  estimate?: Maybe<Scalars['String']>;
  fundoraPortfolioSequence?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  monthlyDividend?: Maybe<Array<Maybe<Scalars['Float']>>>;
  multiplies?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  portfolioId?: Maybe<Scalars['ID']>;
  portfolioName?: Maybe<Scalars['String']>;
  portfolioSlug?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  priceChangePercent?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ContentInput = {
  base?: InputMaybe<Scalars['String']>;
  baseSymbol?: InputMaybe<Scalars['String']>;
  change?: InputMaybe<Scalars['String']>;
  counter?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dividendPerShare?: InputMaybe<Scalars['String']>;
  dividendRatio?: InputMaybe<Scalars['String']>;
  estimate?: InputMaybe<Scalars['String']>;
  fundoraPortfolioSequence?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  monthlyDividend?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  multiplies?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  portfolioId?: InputMaybe<Scalars['ID']>;
  portfolioName?: InputMaybe<Scalars['String']>;
  portfolioSlug?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  priceChangePercent?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  ticker?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type EnterChatRoom = {
  __typename?: 'EnterChatRoom';
  hasEntered?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  newVisit?: Maybe<Scalars['Boolean']>;
};

export type File = {
  __typename?: 'File';
  filename: Scalars['String'];
  height?: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  width?: Maybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'Message';
  content: Array<Maybe<Content>>;
  id?: Maybe<Scalars['ID']>;
  owner: Owner;
  reactions?: Maybe<ReactionType>;
};

export type MessageInput = {
  content: Array<InputMaybe<ContentInput>>;
  owner: OwnerInput;
};

export type Mutation = {
  __typename?: 'Mutation';
  addManagerToChatRoom: ChatRoom;
  addNoticeMessageAtChatRoom: ChatRoom;
  banUserAtChatRoom: ChatRoom;
  createChatRoom: ChatRoom;
  enterChatRoom: EnterChatRoom;
  enterCryptoCurrencyChatRoom: EnterChatRoom;
  enterPublicChatRoom: EnterChatRoom;
  enterStockChatRoom: EnterChatRoom;
  hideMessageAtChatRoom?: Maybe<Message>;
  leaveChatRoom: ChatRoom;
  leavePublicChatRoom: ChatRoom;
  removeBannedUserAtChatRoom: ChatRoom;
  removeManagerToChatRoom: ChatRoom;
  removeNoticeMessageAtChatRoom: ChatRoom;
  replyToMessageAtChatRoom?: Maybe<ReplyMessage>;
  sendMessageToChatRoom?: Maybe<Message>;
  signInChat?: Maybe<User>;
  toggleLikeToMessageAtChatRoom: ChatRoom;
  toggleReactionToMessageAtChatRoom: Message;
  turnOffNotificationStatus: ChatRoom;
  turnOnNotificationStatus: ChatRoom;
  updateNicknameAtChatRoom: ChatRoom;
  updatePasswordAtChatRoom: ChatRoom;
  updateProfileImage: ChatRoom2;
  updateRoomImage: ChatRoom2;
  updateRoomNameAtChatRoom: ChatRoom;
  uploadChatFile: File;
};


export type MutationAddManagerToChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationAddNoticeMessageAtChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  messageId: Scalars['String'];
};


export type MutationBanUserAtChatRoomArgs = {
  banUserId: Scalars['ID'];
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
};


export type MutationCreateChatRoomArgs = {
  chatIdToken: Scalars['String'];
  language: Scalars['String'];
  roomName: Scalars['String'];
  roomType: Scalars['String'];
};


export type MutationEnterChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};


export type MutationEnterCryptoCurrencyChatRoomArgs = {
  chatIdToken: Scalars['String'];
  cryptoCurrencyId: Scalars['ID'];
  language: Scalars['String'];
};


export type MutationEnterPublicChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};


export type MutationEnterStockChatRoomArgs = {
  chatIdToken: Scalars['String'];
  exchange: Scalars['String'];
  language: Scalars['String'];
  ticker: Scalars['String'];
};


export type MutationHideMessageAtChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  messageId: Scalars['ID'];
};


export type MutationLeaveChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
};


export type MutationLeavePublicChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
};


export type MutationRemoveBannedUserAtChatRoomArgs = {
  banUserId: Scalars['ID'];
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
};


export type MutationRemoveManagerToChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationRemoveNoticeMessageAtChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  messageId: Scalars['String'];
};


export type MutationReplyToMessageAtChatRoomArgs = {
  chatId: Scalars['ID'];
  chatIdToken: Scalars['String'];
  message: MessageInput;
  replyToMessageId: Scalars['ID'];
};


export type MutationSendMessageToChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  message: MessageInput;
};


export type MutationSignInChatArgs = {
  authToken: Scalars['String'];
  deviceToken?: InputMaybe<Scalars['String']>;
};


export type MutationToggleLikeToMessageAtChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  messageId: Scalars['String'];
};


export type MutationToggleReactionToMessageAtChatRoomArgs = {
  chatId: Scalars['ID'];
  chatIdToken: Scalars['String'];
  messageId: Scalars['ID'];
  reactionType: ReactionTypeEnum;
};


export type MutationTurnOffNotificationStatusArgs = {
  chatId: Scalars['String'];
  chatIdToken?: InputMaybe<Scalars['String']>;
  deviceToken: Scalars['String'];
};


export type MutationTurnOnNotificationStatusArgs = {
  chatId: Scalars['String'];
  chatIdToken?: InputMaybe<Scalars['String']>;
  deviceToken: Scalars['String'];
};


export type MutationUpdateNicknameAtChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken?: InputMaybe<Scalars['String']>;
  nickname: Scalars['String'];
};


export type MutationUpdatePasswordAtChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};


export type MutationUpdateProfileImageArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  file: Scalars['Upload'];
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateRoomImageArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  file: Scalars['Upload'];
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateRoomNameAtChatRoomArgs = {
  chatId: Scalars['String'];
  chatIdToken?: InputMaybe<Scalars['String']>;
  roomName: Scalars['String'];
};


export type MutationUploadChatFileArgs = {
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  file: Scalars['Upload'];
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type Owner = {
  __typename?: 'Owner';
  id: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  profileImage?: Maybe<Scalars['String']>;
};

export type OwnerInput = {
  id: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  latestMessagesAtChatRoom?: Maybe<Array<Maybe<Message>>>;
  myChatRooms?: Maybe<Array<ChatRoom>>;
  myRecommendChatRooms?: Maybe<Array<ChatRoom>>;
  publicChatRooms?: Maybe<Array<ChatRoom>>;
};


export type QueryLatestMessagesAtChatRoomArgs = {
  chatId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryMyChatRoomsArgs = {
  chatIdToken: Scalars['String'];
};


export type QueryMyRecommendChatRoomsArgs = {
  chatIdToken: Scalars['ID'];
};


export type QueryPublicChatRoomsArgs = {
  chatIdToken: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};

export type ReactionType = {
  __typename?: 'ReactionType';
  heartUserIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  likeUserIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  moneyUserIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  smileUserIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  surpriseUserIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tearsUserIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export enum ReactionTypeEnum {
  Heart = 'HEART',
  Like = 'LIKE',
  Money = 'MONEY',
  Smile = 'SMILE',
  Surprise = 'SURPRISE',
  Tears = 'TEARS'
}

export type ReplyMessage = {
  __typename?: 'ReplyMessage';
  content: Array<Maybe<Content>>;
  id?: Maybe<Scalars['ID']>;
  owner: Owner;
  replyToMessage?: Maybe<Message>;
  replyToMessageId?: Maybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  token?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};

export type SignInChatMutationVariables = Exact<{
  authToken: Scalars['String'];
  deviceToken?: InputMaybe<Scalars['String']>;
}>;


export type SignInChatMutation = { __typename?: 'Mutation', signInChat?: { __typename?: 'User', uid?: string | null, token?: string | null } | null };

export type CreateChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  roomName: Scalars['String'];
  roomType: Scalars['String'];
  language: Scalars['String'];
}>;


export type CreateChatRoomMutation = { __typename?: 'Mutation', createChatRoom: { __typename?: 'ChatRoom', id?: string | null, roomName?: string | null, roomType?: string | null, language?: string | null, linkUrl?: string | null } };

export type EnterChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
}>;


export type EnterChatRoomMutation = { __typename?: 'Mutation', enterChatRoom: { __typename?: 'EnterChatRoom', id?: string | null } };

export type EnterStockChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  exchange: Scalars['String'];
  ticker: Scalars['String'];
  language: Scalars['String'];
}>;


export type EnterStockChatRoomMutation = { __typename?: 'Mutation', enterStockChatRoom: { __typename?: 'EnterChatRoom', id?: string | null } };

export type EnterCryptoCurrencyChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  cryptoCurrencyId: Scalars['ID'];
  language: Scalars['String'];
}>;


export type EnterCryptoCurrencyChatRoomMutation = { __typename?: 'Mutation', enterCryptoCurrencyChatRoom: { __typename?: 'EnterChatRoom', id?: string | null } };

export type LeaveChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
}>;


export type LeaveChatRoomMutation = { __typename?: 'Mutation', leaveChatRoom: { __typename?: 'ChatRoom', id?: string | null } };

export type SendMessageToChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  owner: OwnerInput;
  content: Array<InputMaybe<ContentInput>> | InputMaybe<ContentInput>;
}>;


export type SendMessageToChatRoomMutation = { __typename?: 'Mutation', sendMessageToChatRoom?: { __typename?: 'Message', id?: string | null } | null };

export type UploadChatFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
}>;


export type UploadChatFileMutation = { __typename?: 'Mutation', uploadChatFile: { __typename?: 'File', filename: string, imageUrl: string } };

export type UpdateRoomImageMutationVariables = Exact<{
  file: Scalars['Upload'];
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateRoomImageMutation = { __typename?: 'Mutation', updateRoomImage: { __typename?: 'ChatRoom2', filename: string, roomImageUrl?: string | null, adminId?: string | null, managerIds?: Array<string | null> | null, roomName?: string | null, roomType?: string | null, users?: Array<{ __typename?: 'ChatRoomUser', id?: string | null, nickname?: string | null, profileImage?: string | null } | null> | null } };

export type BanUserAtChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  banUserId: Scalars['ID'];
}>;


export type BanUserAtChatRoomMutation = { __typename?: 'Mutation', banUserAtChatRoom: { __typename?: 'ChatRoom', id?: string | null } };

export type RemoveBannedUserAtChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  banUserId: Scalars['ID'];
}>;


export type RemoveBannedUserAtChatRoomMutation = { __typename?: 'Mutation', removeBannedUserAtChatRoom: { __typename?: 'ChatRoom', id?: string | null } };

export type AddManagerToChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  userId: Scalars['ID'];
}>;


export type AddManagerToChatRoomMutation = { __typename?: 'Mutation', addManagerToChatRoom: { __typename?: 'ChatRoom', id?: string | null } };

export type RemoveManagerToChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  userId: Scalars['ID'];
}>;


export type RemoveManagerToChatRoomMutation = { __typename?: 'Mutation', removeManagerToChatRoom: { __typename?: 'ChatRoom', id?: string | null } };

export type TurnOnNotificationStatusMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  deviceToken: Scalars['String'];
}>;


export type TurnOnNotificationStatusMutation = { __typename?: 'Mutation', turnOnNotificationStatus: { __typename?: 'ChatRoom', id?: string | null } };

export type TurnOffNotificationStatusMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  deviceToken: Scalars['String'];
}>;


export type TurnOffNotificationStatusMutation = { __typename?: 'Mutation', turnOffNotificationStatus: { __typename?: 'ChatRoom', id?: string | null } };

export type UpdatePasswordAtChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  password: Scalars['String'];
}>;


export type UpdatePasswordAtChatRoomMutation = { __typename?: 'Mutation', updatePasswordAtChatRoom: { __typename?: 'ChatRoom', id?: string | null } };

export type UpdateRoomNameAtChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  roomName: Scalars['String'];
}>;


export type UpdateRoomNameAtChatRoomMutation = { __typename?: 'Mutation', updateRoomNameAtChatRoom: { __typename?: 'ChatRoom', id?: string | null } };

export type AddNoticeMessageAtChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  messageId: Scalars['String'];
}>;


export type AddNoticeMessageAtChatRoomMutation = { __typename?: 'Mutation', addNoticeMessageAtChatRoom: { __typename?: 'ChatRoom', id?: string | null } };

export type HideMessageAtChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  messageId: Scalars['ID'];
}>;


export type HideMessageAtChatRoomMutation = { __typename?: 'Mutation', hideMessageAtChatRoom?: { __typename?: 'Message', id?: string | null } | null };

export type ToggleReactionToMessageAtChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['ID'];
  messageId: Scalars['ID'];
  reactionType: ReactionTypeEnum;
}>;


export type ToggleReactionToMessageAtChatRoomMutation = { __typename?: 'Mutation', toggleReactionToMessageAtChatRoom: { __typename?: 'Message', id?: string | null } };

export type ReplyToMessageAtChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['ID'];
  replyToMessageId: Scalars['ID'];
  owner: OwnerInput;
  content: Array<InputMaybe<ContentInput>> | InputMaybe<ContentInput>;
}>;


export type ReplyToMessageAtChatRoomMutation = { __typename?: 'Mutation', replyToMessageAtChatRoom?: { __typename?: 'ReplyMessage', id?: string | null } | null };

export type UpdateNicknameAtChatRoomMutationVariables = Exact<{
  chatIdToken: Scalars['String'];
  chatId: Scalars['String'];
  nickname: Scalars['String'];
}>;


export type UpdateNicknameAtChatRoomMutation = { __typename?: 'Mutation', updateNicknameAtChatRoom: { __typename?: 'ChatRoom', id?: string | null } };

export type UpdateProfileImageMutationVariables = Exact<{
  file: Scalars['Upload'];
  chatId: Scalars['String'];
  chatIdToken: Scalars['String'];
  width?: InputMaybe<Scalars['Int']>;
  height?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateProfileImageMutation = { __typename?: 'Mutation', updateProfileImage: { __typename?: 'ChatRoom2', id?: string | null } };


export const SignInChatDocument = gql`
    mutation SignInChat($authToken: String!, $deviceToken: String) {
  signInChat(authToken: $authToken, deviceToken: $deviceToken) {
    uid
    token
  }
}
    `;
export type SignInChatMutationFn = Apollo.MutationFunction<SignInChatMutation, SignInChatMutationVariables>;

/**
 * __useSignInChatMutation__
 *
 * To run a mutation, you first call `useSignInChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInChatMutation, { data, loading, error }] = useSignInChatMutation({
 *   variables: {
 *      authToken: // value for 'authToken'
 *      deviceToken: // value for 'deviceToken'
 *   },
 * });
 */
export function useSignInChatMutation(baseOptions?: Apollo.MutationHookOptions<SignInChatMutation, SignInChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInChatMutation, SignInChatMutationVariables>(SignInChatDocument, options);
      }
export type SignInChatMutationHookResult = ReturnType<typeof useSignInChatMutation>;
export type SignInChatMutationResult = Apollo.MutationResult<SignInChatMutation>;
export type SignInChatMutationOptions = Apollo.BaseMutationOptions<SignInChatMutation, SignInChatMutationVariables>;
export const CreateChatRoomDocument = gql`
    mutation CreateChatRoom($chatIdToken: String!, $roomName: String!, $roomType: String!, $language: String!) {
  createChatRoom(
    chatIdToken: $chatIdToken
    roomName: $roomName
    roomType: $roomType
    language: $language
  ) {
    id
    roomName
    roomType
    language
    linkUrl
  }
}
    `;
export type CreateChatRoomMutationFn = Apollo.MutationFunction<CreateChatRoomMutation, CreateChatRoomMutationVariables>;

/**
 * __useCreateChatRoomMutation__
 *
 * To run a mutation, you first call `useCreateChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatRoomMutation, { data, loading, error }] = useCreateChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      roomName: // value for 'roomName'
 *      roomType: // value for 'roomType'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCreateChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateChatRoomMutation, CreateChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChatRoomMutation, CreateChatRoomMutationVariables>(CreateChatRoomDocument, options);
      }
export type CreateChatRoomMutationHookResult = ReturnType<typeof useCreateChatRoomMutation>;
export type CreateChatRoomMutationResult = Apollo.MutationResult<CreateChatRoomMutation>;
export type CreateChatRoomMutationOptions = Apollo.BaseMutationOptions<CreateChatRoomMutation, CreateChatRoomMutationVariables>;
export const EnterChatRoomDocument = gql`
    mutation EnterChatRoom($chatIdToken: String!, $chatId: String!, $password: String) {
  enterChatRoom(chatIdToken: $chatIdToken, chatId: $chatId, password: $password) {
    id
  }
}
    `;
export type EnterChatRoomMutationFn = Apollo.MutationFunction<EnterChatRoomMutation, EnterChatRoomMutationVariables>;

/**
 * __useEnterChatRoomMutation__
 *
 * To run a mutation, you first call `useEnterChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterChatRoomMutation, { data, loading, error }] = useEnterChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useEnterChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<EnterChatRoomMutation, EnterChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnterChatRoomMutation, EnterChatRoomMutationVariables>(EnterChatRoomDocument, options);
      }
export type EnterChatRoomMutationHookResult = ReturnType<typeof useEnterChatRoomMutation>;
export type EnterChatRoomMutationResult = Apollo.MutationResult<EnterChatRoomMutation>;
export type EnterChatRoomMutationOptions = Apollo.BaseMutationOptions<EnterChatRoomMutation, EnterChatRoomMutationVariables>;
export const EnterStockChatRoomDocument = gql`
    mutation EnterStockChatRoom($chatIdToken: String!, $exchange: String!, $ticker: String!, $language: String!) {
  enterStockChatRoom(
    chatIdToken: $chatIdToken
    exchange: $exchange
    ticker: $ticker
    language: $language
  ) {
    id
  }
}
    `;
export type EnterStockChatRoomMutationFn = Apollo.MutationFunction<EnterStockChatRoomMutation, EnterStockChatRoomMutationVariables>;

/**
 * __useEnterStockChatRoomMutation__
 *
 * To run a mutation, you first call `useEnterStockChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterStockChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterStockChatRoomMutation, { data, loading, error }] = useEnterStockChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      exchange: // value for 'exchange'
 *      ticker: // value for 'ticker'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useEnterStockChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<EnterStockChatRoomMutation, EnterStockChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnterStockChatRoomMutation, EnterStockChatRoomMutationVariables>(EnterStockChatRoomDocument, options);
      }
export type EnterStockChatRoomMutationHookResult = ReturnType<typeof useEnterStockChatRoomMutation>;
export type EnterStockChatRoomMutationResult = Apollo.MutationResult<EnterStockChatRoomMutation>;
export type EnterStockChatRoomMutationOptions = Apollo.BaseMutationOptions<EnterStockChatRoomMutation, EnterStockChatRoomMutationVariables>;
export const EnterCryptoCurrencyChatRoomDocument = gql`
    mutation EnterCryptoCurrencyChatRoom($chatIdToken: String!, $cryptoCurrencyId: ID!, $language: String!) {
  enterCryptoCurrencyChatRoom(
    chatIdToken: $chatIdToken
    cryptoCurrencyId: $cryptoCurrencyId
    language: $language
  ) {
    id
  }
}
    `;
export type EnterCryptoCurrencyChatRoomMutationFn = Apollo.MutationFunction<EnterCryptoCurrencyChatRoomMutation, EnterCryptoCurrencyChatRoomMutationVariables>;

/**
 * __useEnterCryptoCurrencyChatRoomMutation__
 *
 * To run a mutation, you first call `useEnterCryptoCurrencyChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterCryptoCurrencyChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterCryptoCurrencyChatRoomMutation, { data, loading, error }] = useEnterCryptoCurrencyChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      cryptoCurrencyId: // value for 'cryptoCurrencyId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useEnterCryptoCurrencyChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<EnterCryptoCurrencyChatRoomMutation, EnterCryptoCurrencyChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnterCryptoCurrencyChatRoomMutation, EnterCryptoCurrencyChatRoomMutationVariables>(EnterCryptoCurrencyChatRoomDocument, options);
      }
export type EnterCryptoCurrencyChatRoomMutationHookResult = ReturnType<typeof useEnterCryptoCurrencyChatRoomMutation>;
export type EnterCryptoCurrencyChatRoomMutationResult = Apollo.MutationResult<EnterCryptoCurrencyChatRoomMutation>;
export type EnterCryptoCurrencyChatRoomMutationOptions = Apollo.BaseMutationOptions<EnterCryptoCurrencyChatRoomMutation, EnterCryptoCurrencyChatRoomMutationVariables>;
export const LeaveChatRoomDocument = gql`
    mutation LeaveChatRoom($chatIdToken: String!, $chatId: String!) {
  leaveChatRoom(chatIdToken: $chatIdToken, chatId: $chatId) {
    id
  }
}
    `;
export type LeaveChatRoomMutationFn = Apollo.MutationFunction<LeaveChatRoomMutation, LeaveChatRoomMutationVariables>;

/**
 * __useLeaveChatRoomMutation__
 *
 * To run a mutation, you first call `useLeaveChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveChatRoomMutation, { data, loading, error }] = useLeaveChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useLeaveChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<LeaveChatRoomMutation, LeaveChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveChatRoomMutation, LeaveChatRoomMutationVariables>(LeaveChatRoomDocument, options);
      }
export type LeaveChatRoomMutationHookResult = ReturnType<typeof useLeaveChatRoomMutation>;
export type LeaveChatRoomMutationResult = Apollo.MutationResult<LeaveChatRoomMutation>;
export type LeaveChatRoomMutationOptions = Apollo.BaseMutationOptions<LeaveChatRoomMutation, LeaveChatRoomMutationVariables>;
export const SendMessageToChatRoomDocument = gql`
    mutation SendMessageToChatRoom($chatIdToken: String!, $chatId: String!, $owner: OwnerInput!, $content: [ContentInput]!) {
  sendMessageToChatRoom(
    chatIdToken: $chatIdToken
    chatId: $chatId
    message: {owner: $owner, content: $content}
  ) {
    id
  }
}
    `;
export type SendMessageToChatRoomMutationFn = Apollo.MutationFunction<SendMessageToChatRoomMutation, SendMessageToChatRoomMutationVariables>;

/**
 * __useSendMessageToChatRoomMutation__
 *
 * To run a mutation, you first call `useSendMessageToChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageToChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageToChatRoomMutation, { data, loading, error }] = useSendMessageToChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      owner: // value for 'owner'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useSendMessageToChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageToChatRoomMutation, SendMessageToChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageToChatRoomMutation, SendMessageToChatRoomMutationVariables>(SendMessageToChatRoomDocument, options);
      }
export type SendMessageToChatRoomMutationHookResult = ReturnType<typeof useSendMessageToChatRoomMutation>;
export type SendMessageToChatRoomMutationResult = Apollo.MutationResult<SendMessageToChatRoomMutation>;
export type SendMessageToChatRoomMutationOptions = Apollo.BaseMutationOptions<SendMessageToChatRoomMutation, SendMessageToChatRoomMutationVariables>;
export const UploadChatFileDocument = gql`
    mutation UploadChatFile($file: Upload!, $chatId: String!, $chatIdToken: String!, $width: Int, $height: Int) {
  uploadChatFile(
    file: $file
    chatId: $chatId
    chatIdToken: $chatIdToken
    width: $width
    height: $height
  ) {
    filename
    imageUrl
  }
}
    `;
export type UploadChatFileMutationFn = Apollo.MutationFunction<UploadChatFileMutation, UploadChatFileMutationVariables>;

/**
 * __useUploadChatFileMutation__
 *
 * To run a mutation, you first call `useUploadChatFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadChatFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadChatFileMutation, { data, loading, error }] = useUploadChatFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      chatId: // value for 'chatId'
 *      chatIdToken: // value for 'chatIdToken'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useUploadChatFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadChatFileMutation, UploadChatFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadChatFileMutation, UploadChatFileMutationVariables>(UploadChatFileDocument, options);
      }
export type UploadChatFileMutationHookResult = ReturnType<typeof useUploadChatFileMutation>;
export type UploadChatFileMutationResult = Apollo.MutationResult<UploadChatFileMutation>;
export type UploadChatFileMutationOptions = Apollo.BaseMutationOptions<UploadChatFileMutation, UploadChatFileMutationVariables>;
export const UpdateRoomImageDocument = gql`
    mutation UpdateRoomImage($file: Upload!, $chatId: String!, $chatIdToken: String!, $width: Int, $height: Int) {
  updateRoomImage(
    file: $file
    chatId: $chatId
    chatIdToken: $chatIdToken
    width: $width
    height: $height
  ) {
    filename
    roomImageUrl
    adminId
    managerIds
    roomName
    roomType
    users {
      id
      nickname
      profileImage
    }
  }
}
    `;
export type UpdateRoomImageMutationFn = Apollo.MutationFunction<UpdateRoomImageMutation, UpdateRoomImageMutationVariables>;

/**
 * __useUpdateRoomImageMutation__
 *
 * To run a mutation, you first call `useUpdateRoomImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomImageMutation, { data, loading, error }] = useUpdateRoomImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *      chatId: // value for 'chatId'
 *      chatIdToken: // value for 'chatIdToken'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useUpdateRoomImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomImageMutation, UpdateRoomImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoomImageMutation, UpdateRoomImageMutationVariables>(UpdateRoomImageDocument, options);
      }
export type UpdateRoomImageMutationHookResult = ReturnType<typeof useUpdateRoomImageMutation>;
export type UpdateRoomImageMutationResult = Apollo.MutationResult<UpdateRoomImageMutation>;
export type UpdateRoomImageMutationOptions = Apollo.BaseMutationOptions<UpdateRoomImageMutation, UpdateRoomImageMutationVariables>;
export const BanUserAtChatRoomDocument = gql`
    mutation BanUserAtChatRoom($chatIdToken: String!, $chatId: String!, $banUserId: ID!) {
  banUserAtChatRoom(
    chatIdToken: $chatIdToken
    chatId: $chatId
    banUserId: $banUserId
  ) {
    id
  }
}
    `;
export type BanUserAtChatRoomMutationFn = Apollo.MutationFunction<BanUserAtChatRoomMutation, BanUserAtChatRoomMutationVariables>;

/**
 * __useBanUserAtChatRoomMutation__
 *
 * To run a mutation, you first call `useBanUserAtChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBanUserAtChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [banUserAtChatRoomMutation, { data, loading, error }] = useBanUserAtChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      banUserId: // value for 'banUserId'
 *   },
 * });
 */
export function useBanUserAtChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<BanUserAtChatRoomMutation, BanUserAtChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BanUserAtChatRoomMutation, BanUserAtChatRoomMutationVariables>(BanUserAtChatRoomDocument, options);
      }
export type BanUserAtChatRoomMutationHookResult = ReturnType<typeof useBanUserAtChatRoomMutation>;
export type BanUserAtChatRoomMutationResult = Apollo.MutationResult<BanUserAtChatRoomMutation>;
export type BanUserAtChatRoomMutationOptions = Apollo.BaseMutationOptions<BanUserAtChatRoomMutation, BanUserAtChatRoomMutationVariables>;
export const RemoveBannedUserAtChatRoomDocument = gql`
    mutation RemoveBannedUserAtChatRoom($chatIdToken: String!, $chatId: String!, $banUserId: ID!) {
  removeBannedUserAtChatRoom(
    chatIdToken: $chatIdToken
    chatId: $chatId
    banUserId: $banUserId
  ) {
    id
  }
}
    `;
export type RemoveBannedUserAtChatRoomMutationFn = Apollo.MutationFunction<RemoveBannedUserAtChatRoomMutation, RemoveBannedUserAtChatRoomMutationVariables>;

/**
 * __useRemoveBannedUserAtChatRoomMutation__
 *
 * To run a mutation, you first call `useRemoveBannedUserAtChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBannedUserAtChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBannedUserAtChatRoomMutation, { data, loading, error }] = useRemoveBannedUserAtChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      banUserId: // value for 'banUserId'
 *   },
 * });
 */
export function useRemoveBannedUserAtChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<RemoveBannedUserAtChatRoomMutation, RemoveBannedUserAtChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveBannedUserAtChatRoomMutation, RemoveBannedUserAtChatRoomMutationVariables>(RemoveBannedUserAtChatRoomDocument, options);
      }
export type RemoveBannedUserAtChatRoomMutationHookResult = ReturnType<typeof useRemoveBannedUserAtChatRoomMutation>;
export type RemoveBannedUserAtChatRoomMutationResult = Apollo.MutationResult<RemoveBannedUserAtChatRoomMutation>;
export type RemoveBannedUserAtChatRoomMutationOptions = Apollo.BaseMutationOptions<RemoveBannedUserAtChatRoomMutation, RemoveBannedUserAtChatRoomMutationVariables>;
export const AddManagerToChatRoomDocument = gql`
    mutation AddManagerToChatRoom($chatIdToken: String!, $chatId: String!, $userId: ID!) {
  addManagerToChatRoom(
    chatIdToken: $chatIdToken
    chatId: $chatId
    userId: $userId
  ) {
    id
  }
}
    `;
export type AddManagerToChatRoomMutationFn = Apollo.MutationFunction<AddManagerToChatRoomMutation, AddManagerToChatRoomMutationVariables>;

/**
 * __useAddManagerToChatRoomMutation__
 *
 * To run a mutation, you first call `useAddManagerToChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManagerToChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManagerToChatRoomMutation, { data, loading, error }] = useAddManagerToChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddManagerToChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<AddManagerToChatRoomMutation, AddManagerToChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddManagerToChatRoomMutation, AddManagerToChatRoomMutationVariables>(AddManagerToChatRoomDocument, options);
      }
export type AddManagerToChatRoomMutationHookResult = ReturnType<typeof useAddManagerToChatRoomMutation>;
export type AddManagerToChatRoomMutationResult = Apollo.MutationResult<AddManagerToChatRoomMutation>;
export type AddManagerToChatRoomMutationOptions = Apollo.BaseMutationOptions<AddManagerToChatRoomMutation, AddManagerToChatRoomMutationVariables>;
export const RemoveManagerToChatRoomDocument = gql`
    mutation RemoveManagerToChatRoom($chatIdToken: String!, $chatId: String!, $userId: ID!) {
  removeManagerToChatRoom(
    chatIdToken: $chatIdToken
    chatId: $chatId
    userId: $userId
  ) {
    id
  }
}
    `;
export type RemoveManagerToChatRoomMutationFn = Apollo.MutationFunction<RemoveManagerToChatRoomMutation, RemoveManagerToChatRoomMutationVariables>;

/**
 * __useRemoveManagerToChatRoomMutation__
 *
 * To run a mutation, you first call `useRemoveManagerToChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveManagerToChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeManagerToChatRoomMutation, { data, loading, error }] = useRemoveManagerToChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveManagerToChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<RemoveManagerToChatRoomMutation, RemoveManagerToChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveManagerToChatRoomMutation, RemoveManagerToChatRoomMutationVariables>(RemoveManagerToChatRoomDocument, options);
      }
export type RemoveManagerToChatRoomMutationHookResult = ReturnType<typeof useRemoveManagerToChatRoomMutation>;
export type RemoveManagerToChatRoomMutationResult = Apollo.MutationResult<RemoveManagerToChatRoomMutation>;
export type RemoveManagerToChatRoomMutationOptions = Apollo.BaseMutationOptions<RemoveManagerToChatRoomMutation, RemoveManagerToChatRoomMutationVariables>;
export const TurnOnNotificationStatusDocument = gql`
    mutation TurnOnNotificationStatus($chatIdToken: String!, $chatId: String!, $deviceToken: String!) {
  turnOnNotificationStatus(
    chatIdToken: $chatIdToken
    chatId: $chatId
    deviceToken: $deviceToken
  ) {
    id
  }
}
    `;
export type TurnOnNotificationStatusMutationFn = Apollo.MutationFunction<TurnOnNotificationStatusMutation, TurnOnNotificationStatusMutationVariables>;

/**
 * __useTurnOnNotificationStatusMutation__
 *
 * To run a mutation, you first call `useTurnOnNotificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTurnOnNotificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [turnOnNotificationStatusMutation, { data, loading, error }] = useTurnOnNotificationStatusMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      deviceToken: // value for 'deviceToken'
 *   },
 * });
 */
export function useTurnOnNotificationStatusMutation(baseOptions?: Apollo.MutationHookOptions<TurnOnNotificationStatusMutation, TurnOnNotificationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TurnOnNotificationStatusMutation, TurnOnNotificationStatusMutationVariables>(TurnOnNotificationStatusDocument, options);
      }
export type TurnOnNotificationStatusMutationHookResult = ReturnType<typeof useTurnOnNotificationStatusMutation>;
export type TurnOnNotificationStatusMutationResult = Apollo.MutationResult<TurnOnNotificationStatusMutation>;
export type TurnOnNotificationStatusMutationOptions = Apollo.BaseMutationOptions<TurnOnNotificationStatusMutation, TurnOnNotificationStatusMutationVariables>;
export const TurnOffNotificationStatusDocument = gql`
    mutation TurnOffNotificationStatus($chatIdToken: String!, $chatId: String!, $deviceToken: String!) {
  turnOffNotificationStatus(
    chatIdToken: $chatIdToken
    chatId: $chatId
    deviceToken: $deviceToken
  ) {
    id
  }
}
    `;
export type TurnOffNotificationStatusMutationFn = Apollo.MutationFunction<TurnOffNotificationStatusMutation, TurnOffNotificationStatusMutationVariables>;

/**
 * __useTurnOffNotificationStatusMutation__
 *
 * To run a mutation, you first call `useTurnOffNotificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTurnOffNotificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [turnOffNotificationStatusMutation, { data, loading, error }] = useTurnOffNotificationStatusMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      deviceToken: // value for 'deviceToken'
 *   },
 * });
 */
export function useTurnOffNotificationStatusMutation(baseOptions?: Apollo.MutationHookOptions<TurnOffNotificationStatusMutation, TurnOffNotificationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TurnOffNotificationStatusMutation, TurnOffNotificationStatusMutationVariables>(TurnOffNotificationStatusDocument, options);
      }
export type TurnOffNotificationStatusMutationHookResult = ReturnType<typeof useTurnOffNotificationStatusMutation>;
export type TurnOffNotificationStatusMutationResult = Apollo.MutationResult<TurnOffNotificationStatusMutation>;
export type TurnOffNotificationStatusMutationOptions = Apollo.BaseMutationOptions<TurnOffNotificationStatusMutation, TurnOffNotificationStatusMutationVariables>;
export const UpdatePasswordAtChatRoomDocument = gql`
    mutation UpdatePasswordAtChatRoom($chatIdToken: String!, $chatId: String!, $password: String!) {
  updatePasswordAtChatRoom(
    chatIdToken: $chatIdToken
    chatId: $chatId
    password: $password
  ) {
    id
  }
}
    `;
export type UpdatePasswordAtChatRoomMutationFn = Apollo.MutationFunction<UpdatePasswordAtChatRoomMutation, UpdatePasswordAtChatRoomMutationVariables>;

/**
 * __useUpdatePasswordAtChatRoomMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordAtChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordAtChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordAtChatRoomMutation, { data, loading, error }] = useUpdatePasswordAtChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdatePasswordAtChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordAtChatRoomMutation, UpdatePasswordAtChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordAtChatRoomMutation, UpdatePasswordAtChatRoomMutationVariables>(UpdatePasswordAtChatRoomDocument, options);
      }
export type UpdatePasswordAtChatRoomMutationHookResult = ReturnType<typeof useUpdatePasswordAtChatRoomMutation>;
export type UpdatePasswordAtChatRoomMutationResult = Apollo.MutationResult<UpdatePasswordAtChatRoomMutation>;
export type UpdatePasswordAtChatRoomMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordAtChatRoomMutation, UpdatePasswordAtChatRoomMutationVariables>;
export const UpdateRoomNameAtChatRoomDocument = gql`
    mutation UpdateRoomNameAtChatRoom($chatIdToken: String!, $chatId: String!, $roomName: String!) {
  updateRoomNameAtChatRoom(
    chatIdToken: $chatIdToken
    chatId: $chatId
    roomName: $roomName
  ) {
    id
  }
}
    `;
export type UpdateRoomNameAtChatRoomMutationFn = Apollo.MutationFunction<UpdateRoomNameAtChatRoomMutation, UpdateRoomNameAtChatRoomMutationVariables>;

/**
 * __useUpdateRoomNameAtChatRoomMutation__
 *
 * To run a mutation, you first call `useUpdateRoomNameAtChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomNameAtChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomNameAtChatRoomMutation, { data, loading, error }] = useUpdateRoomNameAtChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      roomName: // value for 'roomName'
 *   },
 * });
 */
export function useUpdateRoomNameAtChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoomNameAtChatRoomMutation, UpdateRoomNameAtChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoomNameAtChatRoomMutation, UpdateRoomNameAtChatRoomMutationVariables>(UpdateRoomNameAtChatRoomDocument, options);
      }
export type UpdateRoomNameAtChatRoomMutationHookResult = ReturnType<typeof useUpdateRoomNameAtChatRoomMutation>;
export type UpdateRoomNameAtChatRoomMutationResult = Apollo.MutationResult<UpdateRoomNameAtChatRoomMutation>;
export type UpdateRoomNameAtChatRoomMutationOptions = Apollo.BaseMutationOptions<UpdateRoomNameAtChatRoomMutation, UpdateRoomNameAtChatRoomMutationVariables>;
export const AddNoticeMessageAtChatRoomDocument = gql`
    mutation AddNoticeMessageAtChatRoom($chatIdToken: String!, $chatId: String!, $messageId: String!) {
  addNoticeMessageAtChatRoom(
    chatIdToken: $chatIdToken
    chatId: $chatId
    messageId: $messageId
  ) {
    id
  }
}
    `;
export type AddNoticeMessageAtChatRoomMutationFn = Apollo.MutationFunction<AddNoticeMessageAtChatRoomMutation, AddNoticeMessageAtChatRoomMutationVariables>;

/**
 * __useAddNoticeMessageAtChatRoomMutation__
 *
 * To run a mutation, you first call `useAddNoticeMessageAtChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoticeMessageAtChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoticeMessageAtChatRoomMutation, { data, loading, error }] = useAddNoticeMessageAtChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useAddNoticeMessageAtChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<AddNoticeMessageAtChatRoomMutation, AddNoticeMessageAtChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNoticeMessageAtChatRoomMutation, AddNoticeMessageAtChatRoomMutationVariables>(AddNoticeMessageAtChatRoomDocument, options);
      }
export type AddNoticeMessageAtChatRoomMutationHookResult = ReturnType<typeof useAddNoticeMessageAtChatRoomMutation>;
export type AddNoticeMessageAtChatRoomMutationResult = Apollo.MutationResult<AddNoticeMessageAtChatRoomMutation>;
export type AddNoticeMessageAtChatRoomMutationOptions = Apollo.BaseMutationOptions<AddNoticeMessageAtChatRoomMutation, AddNoticeMessageAtChatRoomMutationVariables>;
export const HideMessageAtChatRoomDocument = gql`
    mutation HideMessageAtChatRoom($chatIdToken: String!, $chatId: String!, $messageId: ID!) {
  hideMessageAtChatRoom(
    chatIdToken: $chatIdToken
    chatId: $chatId
    messageId: $messageId
  ) {
    id
  }
}
    `;
export type HideMessageAtChatRoomMutationFn = Apollo.MutationFunction<HideMessageAtChatRoomMutation, HideMessageAtChatRoomMutationVariables>;

/**
 * __useHideMessageAtChatRoomMutation__
 *
 * To run a mutation, you first call `useHideMessageAtChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideMessageAtChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideMessageAtChatRoomMutation, { data, loading, error }] = useHideMessageAtChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useHideMessageAtChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<HideMessageAtChatRoomMutation, HideMessageAtChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HideMessageAtChatRoomMutation, HideMessageAtChatRoomMutationVariables>(HideMessageAtChatRoomDocument, options);
      }
export type HideMessageAtChatRoomMutationHookResult = ReturnType<typeof useHideMessageAtChatRoomMutation>;
export type HideMessageAtChatRoomMutationResult = Apollo.MutationResult<HideMessageAtChatRoomMutation>;
export type HideMessageAtChatRoomMutationOptions = Apollo.BaseMutationOptions<HideMessageAtChatRoomMutation, HideMessageAtChatRoomMutationVariables>;
export const ToggleReactionToMessageAtChatRoomDocument = gql`
    mutation ToggleReactionToMessageAtChatRoom($chatIdToken: String!, $chatId: ID!, $messageId: ID!, $reactionType: ReactionTypeEnum!) {
  toggleReactionToMessageAtChatRoom(
    chatIdToken: $chatIdToken
    chatId: $chatId
    messageId: $messageId
    reactionType: $reactionType
  ) {
    id
  }
}
    `;
export type ToggleReactionToMessageAtChatRoomMutationFn = Apollo.MutationFunction<ToggleReactionToMessageAtChatRoomMutation, ToggleReactionToMessageAtChatRoomMutationVariables>;

/**
 * __useToggleReactionToMessageAtChatRoomMutation__
 *
 * To run a mutation, you first call `useToggleReactionToMessageAtChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleReactionToMessageAtChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleReactionToMessageAtChatRoomMutation, { data, loading, error }] = useToggleReactionToMessageAtChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      messageId: // value for 'messageId'
 *      reactionType: // value for 'reactionType'
 *   },
 * });
 */
export function useToggleReactionToMessageAtChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<ToggleReactionToMessageAtChatRoomMutation, ToggleReactionToMessageAtChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleReactionToMessageAtChatRoomMutation, ToggleReactionToMessageAtChatRoomMutationVariables>(ToggleReactionToMessageAtChatRoomDocument, options);
      }
export type ToggleReactionToMessageAtChatRoomMutationHookResult = ReturnType<typeof useToggleReactionToMessageAtChatRoomMutation>;
export type ToggleReactionToMessageAtChatRoomMutationResult = Apollo.MutationResult<ToggleReactionToMessageAtChatRoomMutation>;
export type ToggleReactionToMessageAtChatRoomMutationOptions = Apollo.BaseMutationOptions<ToggleReactionToMessageAtChatRoomMutation, ToggleReactionToMessageAtChatRoomMutationVariables>;
export const ReplyToMessageAtChatRoomDocument = gql`
    mutation ReplyToMessageAtChatRoom($chatIdToken: String!, $chatId: ID!, $replyToMessageId: ID!, $owner: OwnerInput!, $content: [ContentInput]!) {
  replyToMessageAtChatRoom(
    chatIdToken: $chatIdToken
    chatId: $chatId
    replyToMessageId: $replyToMessageId
    message: {owner: $owner, content: $content}
  ) {
    id
  }
}
    `;
export type ReplyToMessageAtChatRoomMutationFn = Apollo.MutationFunction<ReplyToMessageAtChatRoomMutation, ReplyToMessageAtChatRoomMutationVariables>;

/**
 * __useReplyToMessageAtChatRoomMutation__
 *
 * To run a mutation, you first call `useReplyToMessageAtChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyToMessageAtChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyToMessageAtChatRoomMutation, { data, loading, error }] = useReplyToMessageAtChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      replyToMessageId: // value for 'replyToMessageId'
 *      owner: // value for 'owner'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useReplyToMessageAtChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<ReplyToMessageAtChatRoomMutation, ReplyToMessageAtChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplyToMessageAtChatRoomMutation, ReplyToMessageAtChatRoomMutationVariables>(ReplyToMessageAtChatRoomDocument, options);
      }
export type ReplyToMessageAtChatRoomMutationHookResult = ReturnType<typeof useReplyToMessageAtChatRoomMutation>;
export type ReplyToMessageAtChatRoomMutationResult = Apollo.MutationResult<ReplyToMessageAtChatRoomMutation>;
export type ReplyToMessageAtChatRoomMutationOptions = Apollo.BaseMutationOptions<ReplyToMessageAtChatRoomMutation, ReplyToMessageAtChatRoomMutationVariables>;
export const UpdateNicknameAtChatRoomDocument = gql`
    mutation UpdateNicknameAtChatRoom($chatIdToken: String!, $chatId: String!, $nickname: String!) {
  updateNicknameAtChatRoom(
    chatIdToken: $chatIdToken
    chatId: $chatId
    nickname: $nickname
  ) {
    id
  }
}
    `;
export type UpdateNicknameAtChatRoomMutationFn = Apollo.MutationFunction<UpdateNicknameAtChatRoomMutation, UpdateNicknameAtChatRoomMutationVariables>;

/**
 * __useUpdateNicknameAtChatRoomMutation__
 *
 * To run a mutation, you first call `useUpdateNicknameAtChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNicknameAtChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNicknameAtChatRoomMutation, { data, loading, error }] = useUpdateNicknameAtChatRoomMutation({
 *   variables: {
 *      chatIdToken: // value for 'chatIdToken'
 *      chatId: // value for 'chatId'
 *      nickname: // value for 'nickname'
 *   },
 * });
 */
export function useUpdateNicknameAtChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNicknameAtChatRoomMutation, UpdateNicknameAtChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNicknameAtChatRoomMutation, UpdateNicknameAtChatRoomMutationVariables>(UpdateNicknameAtChatRoomDocument, options);
      }
export type UpdateNicknameAtChatRoomMutationHookResult = ReturnType<typeof useUpdateNicknameAtChatRoomMutation>;
export type UpdateNicknameAtChatRoomMutationResult = Apollo.MutationResult<UpdateNicknameAtChatRoomMutation>;
export type UpdateNicknameAtChatRoomMutationOptions = Apollo.BaseMutationOptions<UpdateNicknameAtChatRoomMutation, UpdateNicknameAtChatRoomMutationVariables>;
export const UpdateProfileImageDocument = gql`
    mutation UpdateProfileImage($file: Upload!, $chatId: String!, $chatIdToken: String!, $width: Int, $height: Int) {
  updateProfileImage(
    file: $file
    chatId: $chatId
    chatIdToken: $chatIdToken
    width: $width
    height: $height
  ) {
    id
  }
}
    `;
export type UpdateProfileImageMutationFn = Apollo.MutationFunction<UpdateProfileImageMutation, UpdateProfileImageMutationVariables>;

/**
 * __useUpdateProfileImageMutation__
 *
 * To run a mutation, you first call `useUpdateProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileImageMutation, { data, loading, error }] = useUpdateProfileImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *      chatId: // value for 'chatId'
 *      chatIdToken: // value for 'chatIdToken'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useUpdateProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileImageMutation, UpdateProfileImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileImageMutation, UpdateProfileImageMutationVariables>(UpdateProfileImageDocument, options);
      }
export type UpdateProfileImageMutationHookResult = ReturnType<typeof useUpdateProfileImageMutation>;
export type UpdateProfileImageMutationResult = Apollo.MutationResult<UpdateProfileImageMutation>;
export type UpdateProfileImageMutationOptions = Apollo.BaseMutationOptions<UpdateProfileImageMutation, UpdateProfileImageMutationVariables>;
export const namedOperations = {
  Mutation: {
    SignInChat: 'SignInChat',
    CreateChatRoom: 'CreateChatRoom',
    EnterChatRoom: 'EnterChatRoom',
    EnterStockChatRoom: 'EnterStockChatRoom',
    EnterCryptoCurrencyChatRoom: 'EnterCryptoCurrencyChatRoom',
    LeaveChatRoom: 'LeaveChatRoom',
    SendMessageToChatRoom: 'SendMessageToChatRoom',
    UploadChatFile: 'UploadChatFile',
    UpdateRoomImage: 'UpdateRoomImage',
    BanUserAtChatRoom: 'BanUserAtChatRoom',
    RemoveBannedUserAtChatRoom: 'RemoveBannedUserAtChatRoom',
    AddManagerToChatRoom: 'AddManagerToChatRoom',
    RemoveManagerToChatRoom: 'RemoveManagerToChatRoom',
    TurnOnNotificationStatus: 'TurnOnNotificationStatus',
    TurnOffNotificationStatus: 'TurnOffNotificationStatus',
    UpdatePasswordAtChatRoom: 'UpdatePasswordAtChatRoom',
    UpdateRoomNameAtChatRoom: 'UpdateRoomNameAtChatRoom',
    AddNoticeMessageAtChatRoom: 'AddNoticeMessageAtChatRoom',
    HideMessageAtChatRoom: 'HideMessageAtChatRoom',
    ToggleReactionToMessageAtChatRoom: 'ToggleReactionToMessageAtChatRoom',
    ReplyToMessageAtChatRoom: 'ReplyToMessageAtChatRoom',
    UpdateNicknameAtChatRoom: 'UpdateNicknameAtChatRoom',
    UpdateProfileImage: 'UpdateProfileImage'
  }
}