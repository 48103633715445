import {
  SignInChatMutation,
  SignInChatMutationVariables,
  SignInChatDocument,
} from '@/gql/generated-chat'
import firebaseMessageToken from '@/api/firebase/firebase-message-token'
import isExpired from '@/utils/jwt/is-expired'
import chatServerApolloClient from '@/apollo-client/chat-server-apollo-client'
import sendErrorLog from '@/service/error-log'
import { authTokenStore } from '@/service/auth/store'
import { errorToast } from '@/utils/toast'

const oneMin = 1000 * 60 // 토큰 유효시간 1분 보정값
let chatToken = ''

type Props = {
  authToken?: string
}

export default async function getChatToken(
  { authToken }: Props = { authToken: authTokenStore().token },
) {
  try {
    if (authToken && (!chatToken || isExpired(chatToken, { correction: oneMin }))) {
      const { messageToken } = await firebaseMessageToken()
      const { data } = await chatServerApolloClient().mutate<
        SignInChatMutation,
        SignInChatMutationVariables
      >({
        mutation: SignInChatDocument,
        variables: {
          authToken,
          deviceToken: messageToken,
        },
      })
      // chatToken: 발급후 1시간 유효
      chatToken = data?.signInChat?.token ?? ''
    }

    return chatToken
  } catch (error) {
    errorToast('failed to request SignInChat')
    sendErrorLog(error as Error)
    return ''
  }
}
