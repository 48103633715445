import { getMessaging, getToken, isSupported } from 'firebase/messaging'

let messageToken = ''

/**
 * 브라우저 접속때마다 토큰을 발급하므로 별도로 local에 저장하지 않음
 */
export default async function firebaseMessageToken() {
  if (messageToken) return { messageToken, messaging: null }
  const isSupport = await isSupported() // safari는 아직 지원안함
  if (!isSupport || !navigator.serviceWorker) return { messageToken, messaging: null }

  const messaging = getMessaging()
  // 주의: 여기 navigator.serviceWorker?.ready 설치 안된 상태에서는 시간이 상당히 소요될 수 있음
  // 마냥 기다릴 수 있는 상황이 발생하기 때문에 2초 기다린 후 바로 clear 시킴
  const serviceWorkerRegistration = await new Promise<ServiceWorkerRegistration | null>(
    (resolve) => {
      const timeoutId = setTimeout(() => resolve(null), 2000)
      navigator.serviceWorker.ready.then((sw) => {
        clearTimeout(timeoutId)
        resolve(sw)
      })
    },
  )
  if (!serviceWorkerRegistration) return { messageToken, messaging: null }

  messageToken = await getToken(messaging, {
    vapidKey: process.env.NEXT_PUBLIC_FCM_VAPID,
    serviceWorkerRegistration,
  })
    .then((currentToken) => {
      if (currentToken) return currentToken

      // Show permission request UI
      // 실제로 진입하진 않아 불필요 해 보이고 브라우저레벨에서 자체적으로 권한 요청 및 해당 함수는 대기 상태로 변경되는 것으로 보임)
      console.log('No registration token available. Request permission to generate one.')
      return ''
    })
    .catch((err) => {
      // 진입 시점
      // 권한 차단된 경우(브라우저 레벨에서 유저가 차단 설정하거나 거절 상태인 경우)
      // serviceWorkerRegistration, vapidKey 값이 유효하지 않은 경우
      console.log('An error occurred while retrieving token. ', err)
      return ''
    })

  return { messageToken, messaging }
}
