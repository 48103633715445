import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client'

import isomorphicApolloClient, { CreateApolloClientProps } from './isomorphic-apollo-client'
import getLink from './http-link'

function createChatServerApolloClient({
  isServer,
}: CreateApolloClientProps): ApolloClient<NormalizedCacheObject> {
  const client = new ApolloClient({
    ssrMode: isServer,
    link: getLink({ uri: process.env.NEXT_PUBLIC_CHAT_SERVER_URL as string }),
    cache: new InMemoryCache(),
  })

  return client
}

const chatServerApolloClient = isomorphicApolloClient(createChatServerApolloClient)

export default chatServerApolloClient
