import { getAuth, signInWithCustomToken as _signInWithCustomToken, getIdToken } from 'firebase/auth'

import isExpired from '@/utils/jwt/is-expired'
import sendErrorLog from '@/service/error-log'
import getChatToken from '@/service/chat/chat-token'
import { authTokenStore } from '@/service/auth/store'

const oneMin = 1000 * 60 // 토큰 유효시간 1분 보정값
let idToken = ''

/**
 * 채팅을 할 수 있도록 권한을 가진 firebase auth 토큰 생성
 * @param chatToken signInChat GQL 통해서 얻은 토큰
 */
export async function signInWithCustomToken() {
  try {
    if (!idToken || isExpired(idToken, { correction: oneMin })) {
      const { token: authToken } = authTokenStore()
      if (!authToken) return idToken
      const chatToken = await getChatToken({ authToken })

      // 종종 getChatToken 쿼리 graphql 서버 통신에 실패하는 경우가 발생하는 것으로 보임
      // Firebase: Invalid assertion format. 3 dot separated segments required. (auth/invalid-custom-token).
      // https://appsignal.com/billionaires/sites/61e80da97ccda61f58305f39/exceptions/incidents/7/samples/61e80da97ccda61f58305f39-992457436303627981016596097801
      // https://app.logrocket.com/lsgb6x/therichweb/s/5-60985d56-7219-40e0-ad06-e9bd0af63f50/1?cursor=1659610124321&filters=%255B%257B%2522id%2522%253A%2522SyaPCf5ac%2522%252C%2522type%2522%253A%2522browserTypeAndVersion%2522%252C%2522params%2522%253A%257B%2522browserType%2522%253A%257B%2522operator%2522%253A%257B%2522name%2522%253A%2522is%2522%252C%2522type%2522%253A%2522IS%2522%252C%2522hasStrings%2522%253Atrue%257D%252C%2522strings%2522%253A%255B%2522CHROME%2522%255D%257D%252C%2522browserVersion%2522%253A%257B%2522operator%2522%253A%257B%2522name%2522%253A%2522any%2520version%2522%252C%2522type%2522%253A%2522CONTAINS%2522%252C%2522hasStrings%2522%253Afalse%252C%2522value%2522%253A%2522%2522%257D%252C%2522strings%2522%253A%255B%2522%2522%255D%257D%257D%257D%252C%257B%2522id%2522%253A%2522HJqt0z9p9%2522%252C%2522type%2522%253A%2522timeOnPage%2522%252C%2522params%2522%253A%257B%2522url%2522%253A%257B%2522operator%2522%253A%257B%2522name%2522%253A%2522contains%2522%252C%2522type%2522%253A%2522CONTAINS%2522%252C%2522hasStrings%2522%253Atrue%257D%252C%2522strings%2522%253A%255B%2522%252Fstock%2522%255D%252C%2522type%2522%253A%2522href%2522%257D%252C%2522time%2522%253A%257B%2522operator%2522%253A%257B%2522name%2522%253A%2522at%2520least%2520once%2522%252C%2522type%2522%253A%2522GT%2522%252C%2522hasStrings%2522%253Afalse%252C%2522hideLabel%2522%253Atrue%252C%2522value%2522%253A%25221s%2522%257D%252C%2522strings%2522%253A%255B%25221s%2522%255D%257D%257D%257D%255D&fromTab=sessions&network_filter=graphql&network_id=1-fetch-14&network_root_tab_id=4953f0ab-c7bc-4bee-8828-d3b86cfc70ce&network_tab_id=4953f0ab-c7bc-4bee-8828-d3b86cfc70ce&newestCursor=1659610800000&range=Custom&rangeEnd=1659610860000&rangeStart=1659607200000&timeRange=1800000
      if (!chatToken) return idToken

      const auth = getAuth()
      await _signInWithCustomToken(auth, chatToken)
      if (auth.currentUser) {
        // idToken: 발급후 1시간 유효
        idToken = await getIdToken(auth.currentUser)
      } else {
        throw new Error('not exist firebase auth.currentUser')
      }
    }

    return idToken
  } catch (error) {
    sendErrorLog(error as Error)
    return ''
  }
}
